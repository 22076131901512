import "./App.css";
import QRCode from "qrcode.react";
import Container from "@mui/material/Container";
import { Grid, TextField, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

function App() {
  const [unitType, setUnitType] = useState("nController");
  const [qrContent, setQRContent] = useState("Hello");

  const handleUnitChange = (event) => {
    setUnitType(event.target.value);
  };

  const getRandomMac = () => {
    return "XX:XX:XX:XX:XX:XX".replace(/X/g, function () {
      return "0123456789ABCDEF".charAt(Math.floor(Math.random() * 16));
    }).toLowerCase();
  };

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() 
            * (max - min + 1)) + min;
  };

  const getRandoSerial = () => {
    return (
      "XXX".replace(/X/g, function () {
        return "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(
          Math.floor(Math.random() * 3)
        );
      }) +
      "XXXXX".replace(/X/g, function () {
        return "0123456789".charAt(Math.floor(Math.random() * 5));
      })
    );
  };

  useEffect(() => {
    let content = {
      mac_address: getRandomMac(),
      serial: getRandoSerial(),
      type: unitType,
    };

    if (unitType === "nController Max" || unitType === "nDriver Max") {
      content.led_ports = randomNumberInRange(1,6);
    }

    if (unitType === "nPanel+" || unitType === "nPanel") {
      content.watt = 40;
      content.voltage = 40;
    }

    setQRContent(JSON.stringify(content, null, 2));
  }, [unitType]);
  return (
    <Container fixed>
      <Box pt={3}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h4">
              nLuminarie Random QR Code Generator
            </Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <FormLabel component="legend">Unit Type</FormLabel>
              <RadioGroup
                row
                aria-label="unit"
                name="row-radio-buttons-group"
                value={unitType}
                onChange={handleUnitChange}
              >
                <FormControlLabel
                  value="nController Max"
                  control={<Radio />}
                  label="nController Max"
                />
                <FormControlLabel
                  value="nDriver Max"
                  control={<Radio />}
                  label="nDriver Max"
                />
                <FormControlLabel
                  value="eDriver"
                  control={<Radio />}
                  label="eDriver"
                />
                <FormControlLabel
                  value="nController"
                  control={<Radio />}
                  label="nController"
                />
                <FormControlLabel
                  value="nDriver"
                  control={<Radio />}
                  label="nDriver"
                />
                <FormControlLabel
                  value="nSensor"
                  control={<Radio />}
                  label="nSensor"
                />
                <FormControlLabel
                  value="nSwitch"
                  control={<Radio />}
                  label="nSwitch"
                />
                <FormControlLabel
                  value="nPanel"
                  control={<Radio />}
                  label="nPanel"
                />
                <FormControlLabel
                  value="nPanel+"
                  control={<Radio />}
                  label="nPanel+"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item>
            <QRCode value={qrContent} size={256} />
          </Grid>

          <Grid item>
            <TextField
              sx={{ m: 1, width: "40ch" }}
              maxRows={7}
              multiline
              disabled
              value={qrContent}
              fullWidth
            ></TextField>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default App;
